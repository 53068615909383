import React from 'react';
import PropTypes from 'prop-types';
import { Stack, TextField } from '@mui/material';
import './CreateRole.css';

export const TextBox = ({ value, setValue }) => {
    return (
        <Stack spacing={4}>
            <TextField value={value} onChange={(e) => setValue(e.target.value)} className="Box" label="Role Name" />
        </Stack>
    );
};

TextBox.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func
};
