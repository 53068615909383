import MainCard from 'components/MainCard';
import PropTypes from 'prop-types';

import {
    Box,
    Button,
    Stack,
    InputLabel,
    OutlinedInput,
    CircularProgress,
    Checkbox,
    FormGroup,
    FormControlLabel,
    CardActions,
    Typography,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material';

import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';

const EditProfileForm = ({ userId }) => {
    const token = useSelector((state) => state.tokenSlice.accessToken);
    const loggedInUser = useSelector((state) => state.userSlice.user);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [roles, setRoles] = useState([]);
    const [rolesDirty, setRolesDirty] = useState(false);

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            roles: []
        },
        onSubmit: async (values) => {
            const data = { name: values.firstName, surname: values.lastName, roles: roles };
            try {
                const response = await fetch('https://run.mocky.io/v3/e9023c75-6a85-4118-91a9-13e2b545ac14', {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                    body: JSON.stringify(data)
                }).then((response) => response.json());
                if (!response['error']) {
                    setDialogOpen(true);
                } else {
                    alert(response['message']);
                }
            } catch (e) {
                console.error(e);
                alert('Something Went Wrong');
            }
        }
    });

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleRoleChange = (e) => {
        setRolesDirty(true);
        setRoles(
            roles.map((role) => {
                if (e.target.name == `${role.name}-${role.id}`) {
                    return {
                        ...role,
                        checked: e.target.checked
                    };
                }
                return role;
            })
        );
    };

    const handleUnload = useCallback(
        (e) => {
            if (formik.dirty || rolesDirty) {
                const message = 'You have unsaved changes, are you sure you want to quit?';
                e.returnValue = message;
                return message;
            }
        },
        [formik.dirty, rolesDirty]
    );

    useEffect(() => {
        window.addEventListener('beforeunload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, [handleUnload]);

    useEffect(() => {
        (async () => {
            const userRes = await fetch('https://run.mocky.io/v3/6f9defbf-608c-440f-b33b-9f0b9026f5f0', {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then((res) => {
                    return res.json();
                })
                .catch((e) => {
                    console.error(e);
                });

            const rolesRes = await fetch('https://run.mocky.io/v3/a51b46a9-673a-49eb-89a2-a8c299a5aae5', {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then((res) => {
                    return res.json();
                })
                .catch((e) => {
                    console.error(e);
                });

            const targetUser = userRes.data.filter((user) => user.id == userId)[0];
            formik.setValues({
                firstName: targetUser.name,
                lastName: targetUser.surname
            });
            setRoles(
                rolesRes.data.map((role) => {
                    return {
                        ...role,
                        checked: targetUser.roles.some((userRole) => userRole.id == role.id)
                    };
                })
            );
            setLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, userId]);

    return (
        <>
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>{'Success'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>User Profile Edited Successfully. ID: {userId}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Close</Button>
                </DialogActions>
            </Dialog>
            <MainCard
                title="User Information"
                border="1px solid"
                contentSX={{ padding: loading ? 2 : 0 }}
                footer={
                    !loading && (
                        <Box
                            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%', padding: '20px 10px' }}
                        >
                            <CardActions>
                                <Button size="large" sx={{ width: '100px' }} onClick={handleDialogClose}>
                                    Cancel
                                </Button>
                            </CardActions>
                            <CardActions>
                                <Button size="large" sx={{ width: '100px' }} variant="contained" onClick={() => formik.submitForm()}>
                                    Save
                                </Button>
                            </CardActions>
                        </Box>
                    )
                }
            >
                {!loading ? (
                    <form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem', gap: '20px', p: '20px' }}>
                            <Stack sx={{ width: '100%' }} spacing={1}>
                                <InputLabel htmlFor="firstName">First Name</InputLabel>
                                <OutlinedInput
                                    id="firstName"
                                    name="firstName"
                                    variant="outlined"
                                    placeholder="Name"
                                    size="large"
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    sx={{ padding: '7px' }}
                                />
                            </Stack>
                            <Stack sx={{ width: '100%' }} spacing={1}>
                                <InputLabel htmlFor="lastName">Last Name</InputLabel>
                                <OutlinedInput
                                    id="lastName"
                                    name="lastName"
                                    variant="outlined"
                                    placeholder="Last Name"
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    sx={{ padding: '7px' }}
                                />
                            </Stack>
                        </Box>
                        {loggedInUser.id != userId && (
                            <>
                                <Typography sx={{ p: '20px', fontWeight: 'bold' }}>Assign Roles</Typography>
                                <Divider />
                                <Box sx={{ p: '20px' }}>
                                    <FormGroup row>
                                        {roles.map((role) => (
                                            <FormControlLabel
                                                key={role.id}
                                                control={
                                                    <Checkbox
                                                        name={`${role.name}-${role.id}`}
                                                        checked={role.checked}
                                                        onChange={handleRoleChange}
                                                    />
                                                }
                                                label={role.name}
                                            />
                                        ))}
                                    </FormGroup>
                                </Box>
                            </>
                        )}
                    </form>
                ) : (
                    <CircularProgress sx={{ display: 'block', margin: 'auto' }} />
                )}
            </MainCard>
        </>
    );
};

EditProfileForm.propTypes = {
    userId: PropTypes.string
};

export default EditProfileForm;
