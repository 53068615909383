import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const SnackbarAlert = ({ alertStatus, setAlertStatus }) => {
    let bgColor = null;
    if (alertStatus.severity == 'success') bgColor = '#52C41A';
    else if (alertStatus.severity == 'error') bgColor = '#F5222D';

    return (
        <Snackbar
            open={alertStatus.show}
            autoHideDuration={6000}
            onClose={() => setAlertStatus({ severity: '', message: '', show: false })}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
        >
            <Alert
                onClose={() => setAlertStatus({ severity: '', message: '', show: false })}
                severity={alertStatus.severity}
                sx={{
                    backgroundColor: bgColor,
                    color: '#FFFFFF'
                }}
            >
                {alertStatus.message}
            </Alert>
        </Snackbar>
    );
};

SnackbarAlert.propTypes = {
    alertStatus: { severity: PropTypes.string, message: PropTypes.string, show: PropTypes.boolean },
    setAlertStatus: PropTypes.func
};
