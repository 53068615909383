import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import CompanyTable from 'pages/dashboard/CompanyTable';

import UserTable from 'pages/dashboard/UserTable';
import RolesTable from 'pages/dashboard/RolesTable';
import { CreateUser } from 'pages/CreateUser';
import { CreateCompany } from 'pages/Company/CreateCompany';

const Dashboard = Loadable(lazy(() => import('pages/dashboard')));
const NotFoundPage = Loadable(lazy(() => import('pages/extra-pages/NotFoundPage')));

// render - user profile
const UserProfile = Loadable(lazy(() => import('pages/personal/UserProfile')));
const EditProfile = Loadable(lazy(() => import('pages/personal/EditProfile')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: 'users',
            element: <Dashboard dataTable={<UserTable />} />
        },
        {
            path: 'profile/:userId',
            element: <UserProfile />
        },
        {
            path: 'roles',
            element: <Dashboard dataTable={<RolesTable />} />
        },
        {
            path: 'companies',
            element: <Dashboard dataTable={<CompanyTable />} />
        },
        {
            path: 'profile/:userId/edit',
            element: <EditProfile />
        },
        {
            path: '*',
            element: <NotFoundPage />
        },
        {
            path: 'company/create',
            element: <CreateCompany />
        },

        {
            path: 'user/create',
            element: <CreateUser />
        }
    ]
};

export { MainRoutes };
