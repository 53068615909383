import React from 'react';
import { Button, Dialog, DialogContent, DialogActions, Stack, colors } from '@mui/material';
import { useState } from 'react';
import { CreateRoleNavbar } from './NavbarCreateRole';
import { createTheme } from '@mui/material/styles';
import './CreateRole.css';
import { TextBox } from './TextBox';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { SnackbarAlert } from '../SnackbarAlert';
createTheme({
    palette: {
        secondary: {
            main: colors.grey[500]
        }
    }
});
export const CreateRole = () => {
    const [open, setopen] = useState(false);
    const [newRole, setNewRole] = useState('');
    const [alertStatus, setAlertStatus] = useState({ severity: '', message: '', show: false });

    const token = useSelector((state) => state['tokenSlice']['accessToken']);

    const handleCreate = () => {
        if (newRole) {
            axios
                .post('https://run.mocky.io/v3/482158f6-8347-42d2-827a-939e81725a8d', newRole, {
                    header: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(() => {
                    setAlertStatus({ severity: 'success', message: 'Role has been created successfully', show: true });
                    setNewRole('');
                })
                .catch((err) => {
                    console.log(err);
                    setAlertStatus({ severity: 'error', message: 'Oops something went wrong...', show: true });
                });
            setopen(false);
        } else {
            setopen(true);
        }
    };
    return (
        <>
            <Button onClick={() => setopen(true)} variant="contained" startIcon={<PlusOutlined />}>
                CreateRole
            </Button>
            <Dialog open={open} onClose={() => setopen(false)} aria-labelledby="dialog-title" aria-describedby="dialog-description">
                <CreateRoleNavbar />

                <DialogContent className="contentcreate">
                    <TextBox value={newRole} setValue={setNewRole} />
                </DialogContent>

                <DialogActions>
                    <Stack spacing={3} direction="row" className="buttonscreate">
                        <Button className="cancel" onClick={() => setopen(false)}>
                            Cancel
                        </Button>
                        <Button className="bluebutton" onClick={handleCreate} autofocus variant="contained" color="primary">
                            Create
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
            <SnackbarAlert alertStatus={alertStatus} setAlertStatus={setAlertStatus} />
        </>
    );
};
