import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';

export const Navbar = () => {
    return (
        <AppBar style={{ background: '#fff' }} position="static">
            <Toolbar>
                <Typography color="common.black">Delete Role</Typography>
            </Toolbar>
        </AppBar>
    );
};
