import React from 'react';
import { IconButton, Stack, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, colors } from '@mui/material';
import { useState } from 'react';
import { Navbar } from './Navbar';
import { WarningOutlined, DeleteOutlined } from '@ant-design/icons';
import { createTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import './DeleteRole.css';
createTheme({
    palette: {
        error: {
            main: colors.red[800]
        }
    }
});
export const DeleteRole = ({ role }) => {
    const [open, setopen] = useState(false);
    return (
        <>
            <IconButton onClick={() => setopen(true)} color="primary">
                <DeleteOutlined />
            </IconButton>
            <Dialog open={open} onClose={() => setopen(false)} aria-labelledby="dialog-title" aria-describedby="dialog-description">
                <Navbar />
                <DialogTitle id="dialog-title" color="error">
                    <WarningOutlined />
                    {'  '} Warning{' '}
                </DialogTitle>
                <DialogContent className="contentdelete">
                    <DialogContentText color="common.black" id="dialog-description">
                        Are you sure you want to delete {role.name} role?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="buttonsdelete">
                    <Stack spacing={3} direction="row">
                        <Button className="cancel" onClick={() => setopen(false)}>
                            Cancel
                        </Button>
                        <Button className="redbutton" onClick={() => setopen(false)} autofocus variant="contained" color="error">
                            Delete
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    );
};

DeleteRole.propTypes = {
    role: { id: PropTypes.string, name: PropTypes.string }
};
