// project import
import RolesIcon from 'components/RolesIcon';
import { UserOutlined } from '@ant-design/icons';
import { MdOutlineBusiness } from 'react-icons/md';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [
        {
            id: 'users',
            title: 'User List',
            navTitle: 'Users',
            url: '/users',
            icon: UserOutlined
        },
        {
            id: 'roles',
            title: 'Roles',
            navTitle: 'Roles',
            url: '/roles',
            icon: RolesIcon
        },
        {
            id: 'companies',
            title: 'Company List',
            navTitle: 'Companies',
            url: '/companies',
            icon: MdOutlineBusiness
        }
    ]
};

export default menuItems;
