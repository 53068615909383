import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import Fuse from 'fuse.js';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

// material-ui
import {
    Grid,
    Modal,
    Divider,
    CircularProgress,
    Button,
    Box,
    Table,
    TableBody,
    TableCell,
    IconButton,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';

import { EyeOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import Search from './Search';
import EditProfileForm from 'pages/personal/EditProfileForm';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// ==============================|| ORDER TABLE - HEADER CELL ||============================== //

const headCells = [
    {
        id: 'id',
        disablePadding: false,
        label: '# ID'
    },
    {
        id: 'first_name',
        disablePadding: true,
        label: 'First Name'
    },
    {
        id: 'last_name',
        disablePadding: false,
        label: 'Last Name'
    },
    {
        id: 'email',
        disablePadding: false,
        label: 'Email'
    },
    {
        id: 'role',
        disablePadding: false,
        label: 'Roles'
    },
    {
        id: 'actions',
        disablePadding: false,
        label: 'Actions'
    }
];

// ==============================|| ORDER TABLE - HEADER ||============================== //

function UserTableHead({ order, orderBy }) {
    return (
        <TableHead>
            <TableRow sx={{ '& th:not(:last-child)': { borderRight: '1px solid #f0f0f0' } }}>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

UserTableHead.propTypes = {
    order: PropTypes.string,
    orderBy: PropTypes.string
};

// ==============================|| ORDER TABLE ||============================== //

const fuseOptions = {
    includeScore: true,
    keys: ['id', 'name', 'surname', 'email', 'roles.name']
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '95%',
    width: '800px',
    maxHeight: '100%',
    overflowY: 'auto'
};

export default function UserTable() {
    const [order] = useState('asc');
    const [orderBy] = useState('id');
    const [selected] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [userId, setUserId] = useState(null);

    const fuse = new Fuse(users, fuseOptions);
    const token = useSelector((state) => state.tokenSlice.accessToken);

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleSearchChange = (e) => {
        if (!e.target.value) {
            setFilteredRows(users);
            return;
        }

        const result = fuse.search(e.target.value).sort((a, b) => {
            if (a.score && b.score) {
                return a.score < b.score ? 1 : -1;
            }
            return 0;
        });

        const newRows = result.reduce((prevVal, currVal) => {
            prevVal.push(currVal.item);
            return prevVal;
        }, []);

        setFilteredRows(newRows);
    };

    const handleEditUser = (userId) => {
        setUserId(userId);
        setModalOpen(true);
    };
    const handleModalClose = () => {
        setUserId(null);
        setModalOpen(false);
    };

    useEffect(() => {
        (async () => {
            const res = await fetch('https://run.mocky.io/v3/6f9defbf-608c-440f-b33b-9f0b9026f5f0', {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then((res) => {
                    return res.json();
                })
                .catch((e) => {
                    console.error(e);
                });

            setUsers(res.data);
            setFilteredRows(res.data);
            setLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box>
            <Modal open={modalOpen} onClose={handleModalClose}>
                <Box sx={modalStyle}>
                    <EditProfileForm userId={userId?.toString()} />
                </Box>
            </Modal>
            <Grid container alignItems="center" sx={{ py: 2, px: 2 }} justifyContent="space-between">
                <Grid item xs={6}>
                    <Search onChange={handleSearchChange} />
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        startIcon={<PlusOutlined />}
                        onClick={() => {
                            navigate('/createuser');
                        }}
                    >
                        Create User
                    </Button>
                </Grid>
            </Grid>
            <Divider />
            <TableContainer
                sx={{
                    width: '100%',
                    overflowX: 'auto',
                    position: 'relative',
                    display: 'block',
                    maxWidth: '100%',
                    '& td, & th': { whiteSpace: 'nowrap' }
                }}
            >
                <Table
                    aria-labelledby="tableTitle"
                    sx={{
                        '& .MuiTableCell-root:first-child': {
                            pl: 2
                        }
                    }}
                >
                    <UserTableHead order={order} orderBy={orderBy} />
                    <TableBody>
                        {isLoading && (
                            <TableCell key="spinner" colSpan="6">
                                <CircularProgress data-testid="spinner" sx={{ display: 'block', margin: 'auto' }} />
                            </TableCell>
                        )}
                        {!filteredRows.length && !isLoading && (
                            <TableCell key="notfound" colSpan="6">
                                <Typography sx={{ p: 2, textAlign: 'center' }}>No matching result found</Typography>
                            </TableCell>
                        )}
                        {stableSort(filteredRows, getComparator(order, orderBy)).map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    sx={{
                                        '&:last-child td, &:last-child th': { borderBottom: 0 },
                                        '& td:not(:last-child)': { borderRight: '1px solid #f0f0f0' }
                                    }}
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    selected={isItemSelected}
                                >
                                    <TableCell id={labelId} scope="row" align="left">
                                        {row.id}
                                    </TableCell>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="left">{row.surname}</TableCell>
                                    <TableCell align="left">{row.email}</TableCell>
                                    <TableCell align="left">
                                        {row.roles
                                            .reduce((prev, curr) => {
                                                prev.push(curr.name);
                                                return prev;
                                            }, [])
                                            .join(' / ')}
                                    </TableCell>
                                    <TableCell align="left">
                                        <IconButton color="primary" onClick={() => navigate(`/profile/${row.id}`)}>
                                            <EyeOutlined />
                                        </IconButton>
                                        <IconButton color="primary" onClick={() => handleEditUser(row.id)}>
                                            <EditOutlined />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
