import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Stack, Button, Dialog, DialogContent, DialogActions, colors } from '@mui/material';
import { useState } from 'react';
import { EditRoleNavbar } from './NavbarEditRole';
import { createTheme } from '@mui/material/styles';
import { TextBox } from 'components/CreateRole/TextBox';
import './EditRole.css';
import { EditOutlined } from '@ant-design/icons';
createTheme({
    palette: {
        secondary: {
            main: colors.grey[500]
        }
    }
});
export const EditRole = ({ role }) => {
    const [open, setopen] = useState(false);
    const [currentRole, setCurrentRole] = useState(role);
    return (
        <>
            <IconButton onClick={() => setopen(true)} color="primary">
                <EditOutlined />
            </IconButton>
            <Dialog open={open} onClose={() => setopen(false)} aria-labelledby="dialog-title" aria-describedby="dialog-description">
                <EditRoleNavbar />

                <DialogContent className="contentedit">
                    <TextBox value={currentRole} setValue={setCurrentRole} />
                </DialogContent>
                <DialogActions>
                    <Stack spacing={3} direction="row" className="buttonsedit">
                        <Button className="cancel" onClick={() => setopen(false)} color="secondary">
                            Cancel
                        </Button>
                        <Button className="bluebuttonsave" onClick={() => setopen(false)} autofocus variant="contained" color="primary">
                            Save
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    );
};

EditRole.propTypes = {
    role: PropTypes.string
};
