import React from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { SnackbarAlert } from 'components/SnackbarAlert';

import { Typography, Stack, Divider, Box, Button, TextField } from '@mui/material';

export const CreateCompany = () => {
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const [alertStatus, setAlertStatus] = useState({ severity: '', message: '', show: false });
    const token = useSelector((state) => state['tokenSlice']['accessToken']);

    useEffect(() => {
        const decodedToken = jwtDecode(token);
        if (!decodedToken.role.includes('ADMIN')) {
            navigate('/login', { replace: true });
        }
    }, [token, navigate]);

    const [enteredData, setEnteredData] = useState({
        companyName: '',
        email: ''
    });

    const resetInputs = () => {
        setEnteredData({
            companyName: '',
            email: ''
        });
    };

    const updateData = (type, value) => {
        let updatedValue = {};
        updatedValue[type] = value;
        setEnteredData((enteredData) => ({
            ...enteredData,
            ...updatedValue
        }));
    };

    useEffect(() => {
        axios
            .get('https://62e82f0a249bb1284eaab408.mockapi.io/api/roles')

            .catch((err) => {
                console.log(err);
                setAlertStatus('error');
            });
    }, []);

    const handleEmailInput = () => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!regex.test(enteredData.email)) {
            setError(true);
        } else {
            setError(false);
        }
    };

    const handleButtons = (e) => {
        if (e.currentTarget.value === 'cancel') {
            navigate(-1);
        } else if (enteredData.email && enteredData.companyName && !error) {
            axios
                .post('https://63ebaabe3eccf93245c98c97.mockapi.io/CompanyNames', enteredData)
                .then(() => {
                    setAlertStatus({ severity: 'success', message: 'Role has been created successfully', show: true });
                    resetInputs();
                })
                .catch((err) => {
                    console.log(err);
                    setAlertStatus({ severity: 'error', message: 'Oops something went wrong...', show: true });
                });
        }
    };

    return (
        <Stack spacing={2}>
            <Box
                sx={{
                    width: '980px',
                    borderRadius: 2,
                    marginTop: '36px'
                }}
                border={1}
                borderColor="#E0E0E0"
            >
                <Typography
                    variant="subtitle1"
                    color="#404D61"
                    fontFamily="Inter"
                    fontWeight="500"
                    fontSize="14px"
                    sx={{
                        marginTop: '32px',
                        marginLeft: '29px',
                        marginBottom: '32px'
                    }}
                >
                    Company Information
                </Typography>
                <Divider />

                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        marginTop: '32px',
                        marginLeft: '29px',
                        marginBottom: '32px',
                        marginRight: '29px'
                    }}
                >
                    <Stack spacing={2} sx={{ width: '50%' }}>
                        <Typography variant="subtitle1" color="#667181" fontFamily="Inter" fontSize="14px">
                            Company Name
                        </Typography>
                        <TextField
                            fullWidth
                            label="Company Name"
                            value={enteredData.companyName}
                            onChange={(e) => {
                                updateData('companyName', e.target.value);
                            }}
                        />
                    </Stack>
                </Stack>

                <Divider />

                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        marginTop: '21px',
                        marginLeft: '29px',
                        marginBottom: '32px',
                        marginRight: '29px'
                    }}
                >
                    <Stack spacing={2} sx={{ width: '50%' }}>
                        <Typography variant="subtitle1" color="#667181" fontFamily="Inter" fontSize="14px">
                            Contact Email
                        </Typography>
                        <TextField
                            onChange={(e) => {
                                updateData('email', e.target.value);
                            }}
                            fullWidth
                            label="Email"
                            value={enteredData.email}
                            inputProps={{
                                onBlur: () => {
                                    handleEmailInput();
                                }
                            }}
                            error={error}
                        />
                    </Stack>
                </Stack>

                <Stack direction="row" justifyContent="flex-end" sx={{ marginTop: '40px', marginRight: '29px', marginBottom: '40px' }}>
                    <Button value="cancel" onClick={handleButtons} variant="text" sx={{ width: '124px', height: '39px' }}>
                        Cancel
                    </Button>
                    <Button value="create" onClick={handleButtons} variant="contained" sx={{ width: '124px', height: '39px' }}>
                        Create
                    </Button>
                </Stack>
            </Box>
            <SnackbarAlert alertStatus={alertStatus} setAlertStatus={setAlertStatus} />
        </Stack>
    );
};
