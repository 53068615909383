import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import Fuse from 'fuse.js';
import { useSelector } from 'react-redux';

// material-ui
import {
    Grid,
    Divider,
    CircularProgress,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';

import Search from './Search';
import { CreateRole } from 'components/CreateRole/CreateRole';
import { DeleteRole } from 'components/DeleteRole/DeleteRole';
import { EditRole } from 'components/EditRole/EditRole';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// ==============================|| ROLES TABLE - HEADER CELL ||============================== //

const headCells = [
    {
        id: 'id',
        disablePadding: false,
        label: '# ID'
    },
    {
        id: 'name',
        disablePadding: true,
        label: 'Role Name'
    },
    {
        id: 'actions',
        disablePadding: false,
        label: 'Actions'
    }
];

// ==============================|| ROLES TABLE - HEADER ||============================== //

function RolesTableHead({ order, orderBy }) {
    return (
        <TableHead>
            <TableRow sx={{ '& th:not(:last-child)': { borderRight: '1px solid #f0f0f0' } }}>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

RolesTableHead.propTypes = {
    order: PropTypes.string,
    orderBy: PropTypes.string
};

// ==============================|| ROLES TABLE ||============================== //

const fuseOptions = {
    includeScore: true,
    keys: ['id', 'name']
};

export default function RolesTable() {
    const [order] = useState('asc');
    const [orderBy] = useState('id');
    const [selected] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [roles, setRoles] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);

    const fuse = new Fuse(roles, fuseOptions);
    const token = useSelector((state) => state.tokenSlice.accessToken);

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleSearchChange = (e) => {
        if (!e.target.value) {
            setFilteredRows(roles);
            return;
        }

        const result = fuse.search(e.target.value).sort((a, b) => {
            if (a.score && b.score) {
                return a.score < b.score ? 1 : -1;
            }
            return 0;
        });

        const newRows = result.reduce((prevVal, currVal) => {
            prevVal.push(currVal.item);
            return prevVal;
        }, []);

        setFilteredRows(newRows);
    };

    useEffect(() => {
        (async () => {
            const res = await fetch('https://run.mocky.io/v3/a51b46a9-673a-49eb-89a2-a8c299a5aae5', {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then((res) => {
                    return res.json();
                })
                .catch((e) => {
                    console.error(e);
                });

            setRoles(res.data);
            setFilteredRows(res.data);
            setLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box>
            <Grid container alignItems="center" sx={{ py: 2, px: 2 }} justifyContent="space-between">
                <Grid item xs={6}>
                    <Search onChange={handleSearchChange} />
                </Grid>
                <Grid item>
                    <CreateRole />
                </Grid>
            </Grid>
            <Divider />
            <TableContainer
                sx={{
                    width: '100%',
                    overflowX: 'auto',
                    position: 'relative',
                    display: 'block',
                    maxWidth: '100%',
                    '& td, & th': { whiteSpace: 'nowrap' }
                }}
            >
                <Table
                    aria-labelledby="tableTitle"
                    sx={{
                        '& .MuiTableCell-root:first-child': {
                            pl: 2
                        }
                    }}
                >
                    <RolesTableHead order={order} orderBy={orderBy} />
                    <TableBody>
                        {isLoading && (
                            <TableCell key="spinner" colSpan="6">
                                <CircularProgress data-testid="spinner" sx={{ display: 'block', margin: 'auto' }} />
                            </TableCell>
                        )}
                        {!filteredRows.length && !isLoading && (
                            <TableCell key="notfound" colSpan="6">
                                <Typography sx={{ p: 2, textAlign: 'center' }}>No matching result found</Typography>
                            </TableCell>
                        )}
                        {stableSort(filteredRows, getComparator(order, orderBy)).map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    sx={{
                                        '&:last-child td, &:last-child th': { borderBottom: 0 },
                                        '& td:not(:last-child)': { borderRight: '1px solid #f0f0f0' }
                                    }}
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    selected={isItemSelected}
                                >
                                    <TableCell id={labelId} scope="row" align="left">
                                        {row.id}
                                    </TableCell>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="left">
                                        <DeleteRole role={row} />
                                        <EditRole role={row.name} />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
