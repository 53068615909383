import { SvgIcon } from '@mui/material';

export default function RolesIcon(props) {
    return (
        <SvgIcon viewBox="0 0 18 16" {...props}>
            <path d="M12.9026 10.6553C12.214 9.96525 11.3983 9.41508 10.5006 9.03518C10.4925 9.03116 10.4845 9.02915 10.4764 9.02513C11.7287 8.1206 12.5428 6.64724 12.5428 4.98492C12.5428 2.23116 10.3116 0 7.55784 0C4.80407 0 2.57291 2.23116 2.57291 4.98492C2.57291 6.64724 3.38698 8.1206 4.63925 9.02714C4.63121 9.03116 4.62317 9.03317 4.61513 9.03719C3.71462 9.41709 2.90658 9.96181 2.21312 10.6573C1.52308 11.3459 0.972923 12.1615 0.593015 13.0593C0.219793 13.9382 0.0185064 14.8805 5.02633e-05 15.8352C-0.000486229 15.8566 0.00327664 15.878 0.0111173 15.8979C0.0189579 15.9179 0.0307175 15.9361 0.0457033 15.9515C0.0606891 15.9669 0.0785978 15.9791 0.0983742 15.9874C0.118151 15.9957 0.139395 16 0.160854 16H1.36688C1.45533 16 1.52568 15.9296 1.52769 15.8432C1.56789 14.2915 2.191 12.8382 3.29251 11.7367C4.43221 10.597 5.94578 9.96985 7.55784 9.96985C9.1699 9.96985 10.6835 10.597 11.8232 11.7367C12.8182 12.7318 13.9291 11.6779 12.9026 10.6553ZM7.55784 8.44221C6.63523 8.44221 5.76688 8.08241 5.11362 7.42915C4.46035 6.77588 4.10055 5.90754 4.10055 4.98492C4.10055 4.06231 4.46035 3.19397 5.11362 2.5407C5.76688 1.88744 6.63523 1.52764 7.55784 1.52764C8.48045 1.52764 9.34879 1.88744 10.0021 2.5407C10.6553 3.19397 11.0151 4.06231 11.0151 4.98492C11.0151 5.90754 10.6553 6.77588 10.0021 7.42915C9.34879 8.08241 8.48045 8.44221 7.55784 8.44221Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.1691 9.25671C18.5796 9.62617 18.6128 10.2585 18.2434 10.669L13.7434 15.669C13.3838 16.0685 12.7728 16.1123 12.3599 15.7682L9.3599 13.2682C8.93562 12.9147 8.8783 12.2841 9.23186 11.8598C9.58543 11.4355 10.216 11.3782 10.6403 11.7318L12.9008 13.6155L16.7568 9.33104C17.1263 8.92053 17.7585 8.88726 18.1691 9.25671Z"
            />
        </SvgIcon>
    );
}
