import React from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { SnackbarAlert } from 'components/SnackbarAlert';

import {
    Typography,
    Stack,
    Divider,
    Box,
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
    FormControl,
    FormGroup,
    LinearProgress
} from '@mui/material';

export const CreateUser = () => {
    const [error, setError] = useState(false);
    const [fetchedRoles, setFetchedRoles] = useState([]);
    const [alertStatus, setAlertStatus] = useState({ severity: '', message: '', show: false });
    const [isLoading, setLoading] = useState(true);
    const navigate = useNavigate();

    const token = useSelector((state) => state['tokenSlice']['accessToken']);

    useEffect(() => {
        const decodedToken = jwtDecode(token);
        if (!decodedToken.role.includes('ADMIN')) {
            navigate('/login', { replace: true });
        }
    }, [token, navigate]);

    const [enteredData, setEnteredData] = useState({
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        roles: []
    });

    const resetInputs = () => {
        setEnteredData({
            username: '',
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            roles: []
        });
    };

    const updateData = (type, value) => {
        let updatedValue = {};
        updatedValue[type] = value;
        setEnteredData((enteredData) => ({
            ...enteredData,
            ...updatedValue
        }));
    };

    useEffect(() => {
        axios
            .get('https://62e82f0a249bb1284eaab408.mockapi.io/api/roles')
            .then((res) => {
                setLoading(false);
                res.data['0'].data.map((data) => {
                    setFetchedRoles((oldArray) => [...oldArray, data.name]);
                });
                // Because of the mock data"s structure, we need to put data["0"] between
                // Correct use of it is:
                // (res.data).map(data => {
                //     setFetchedRoles(oldArray => [...oldArray, data.name]);
                // });
            })
            .catch((err) => {
                console.log(err);
                setAlertStatus({ severity: 'error', message: 'Oops something went wrong...', show: true });
            });
    }, []);

    const handleCheckbox = (e) => {
        const index = enteredData.roles.indexOf(e.target.value);
        var newArray;
        if (index === -1) {
            newArray = [...enteredData.roles, e.target.value];
        } else {
            newArray = enteredData.roles.filter((myRole) => myRole !== e.target.value);
        }
        updateData('roles', newArray);
    };

    const handleEmailInput = () => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!regex.test(enteredData.email)) {
            setError(true);
        } else {
            setError(false);
        }
    };

    useEffect(() => {
        const fName = enteredData.firstName.concat('.').replace(/\s/g, '');
        const uname = fName.concat(enteredData.lastName).toLowerCase();
        updateData('username', uname);
    }, [enteredData.firstName, enteredData.lastName]);

    const handleButtons = (e) => {
        if (e.currentTarget.value === 'cancel') {
            navigate(-1);
        } else if (
            enteredData.email &&
            enteredData.firstName &&
            enteredData.lastName &&
            enteredData.password &&
            enteredData.roles.length != 0 &&
            !error
        ) {
            axios
                .post('https://62e82f0a249bb1284eaab408.mockapi.io/api/me', enteredData)
                .then(() => {
                    setAlertStatus({ severity: 'success', message: 'User has been created successfully', show: true });
                    resetInputs();
                })
                .catch((err) => {
                    console.log(err);
                    setAlertStatus({ severity: 'error', message: 'Oops something went wrong...', show: true });
                });
        }
    };

    return (
        <Stack spacing={2}>
            <Box
                sx={{
                    width: '980px',
                    borderRadius: 2,
                    marginTop: '36px'
                }}
                border={1}
                borderColor="#E0E0E0"
            >
                <Typography
                    variant="subtitle1"
                    color="#404D61"
                    fontFamily="Inter"
                    fontWeight="500"
                    fontSize="14px"
                    sx={{
                        marginTop: '32px',
                        marginLeft: '29px',
                        marginBottom: '32px'
                    }}
                >
                    User Information
                </Typography>
                <Divider />

                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        marginTop: '32px',
                        marginLeft: '29px',
                        marginBottom: '32px',
                        marginRight: '29px'
                    }}
                >
                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Typography variant="subtitle1" color="#667181" fontFamily="Inter" fontSize="14px">
                            First Name
                        </Typography>
                        <TextField
                            fullWidth
                            label="Name"
                            value={enteredData.firstName}
                            onChange={(e) => {
                                updateData('firstName', e.target.value);
                            }}
                        />
                    </Stack>
                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Typography variant="subtitle1" color="#667181" fontFamily="Inter" fontSize="14px">
                            Last Name
                        </Typography>
                        <TextField
                            fullWidth
                            label="Last Name"
                            value={enteredData.lastName}
                            onChange={(e) => {
                                updateData('lastName', e.target.value);
                            }}
                        />
                    </Stack>
                </Stack>

                <Typography
                    variant="subtitle1"
                    color="#404D61"
                    fontFamily="Inter"
                    fontWeight="500"
                    fontSize="14px"
                    sx={{
                        marginTop: '40px',
                        marginLeft: '29px',
                        marginBottom: '20px'
                    }}
                >
                    Login Information
                </Typography>
                <Divider />

                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        marginTop: '21px',
                        marginLeft: '29px',
                        marginBottom: '32px',
                        marginRight: '29px'
                    }}
                >
                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Typography variant="subtitle1" color="#667181" fontFamily="Inter" fontSize="14px">
                            Email
                        </Typography>
                        <TextField
                            onChange={(e) => {
                                updateData('email', e.target.value);
                            }}
                            fullWidth
                            label="Email"
                            value={enteredData.email}
                            inputProps={{
                                onBlur: () => {
                                    handleEmailInput();
                                }
                            }}
                            error={error}
                        />
                    </Stack>
                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Typography variant="subtitle1" color="#667181" fontFamily="Inter" fontSize="14px">
                            Password
                        </Typography>
                        <TextField
                            fullWidth
                            type="password"
                            label="Password"
                            value={enteredData.password}
                            onChange={(e) => {
                                updateData('password', e.target.value);
                            }}
                        />
                    </Stack>
                </Stack>

                <Typography
                    variant="subtitle1"
                    color="#404D61"
                    fontFamily="Inter"
                    fontWeight="500"
                    fontSize="14px"
                    sx={{
                        marginTop: '40px',
                        marginLeft: '29px',
                        marginBottom: '20px'
                    }}
                >
                    Roles
                </Typography>
                <Divider />
                <Box>
                    <Box data-testid="rolesRow">
                        {isLoading && (
                            <Box
                                data-testid="rolesProgressBar"
                                sx={{
                                    marginLeft: '10px',
                                    marginBottom: '10px',
                                    marginTop: '10px',
                                    width: '95%'
                                }}
                            >
                                <LinearProgress />
                            </Box>
                        )}
                        <FormControl>
                            {!isLoading && (
                                <FormGroup row sx={{ marginLeft: '29px', marginBottom: '32px', marginTop: '21px' }}>
                                    {fetchedRoles.map((myRole) => {
                                        return (
                                            <FormControlLabel
                                                key={myRole}
                                                sx={{ width: '100px', color: '#404D61', weight: '500px' }}
                                                label={myRole}
                                                control={
                                                    <Checkbox
                                                        value={myRole}
                                                        checked={enteredData.roles.includes(myRole)}
                                                        onChange={handleCheckbox}
                                                    />
                                                }
                                            ></FormControlLabel>
                                        );
                                    })}
                                </FormGroup>
                            )}
                        </FormControl>
                    </Box>
                </Box>
                <Divider />

                <Stack direction="row" justifyContent="flex-end" sx={{ marginTop: '40px', marginRight: '29px', marginBottom: '40px' }}>
                    <Button value="cancel" onClick={handleButtons} variant="text" sx={{ width: '124px', height: '39px' }}>
                        Cancel
                    </Button>
                    <Button value="create" onClick={handleButtons} variant="contained" sx={{ width: '124px', height: '39px' }}>
                        Create
                    </Button>
                </Stack>
            </Box>
            <SnackbarAlert alertStatus={alertStatus} setAlertStatus={setAlertStatus} />
        </Stack>
    );
};
